import React, { createContext, useContext, useState, ReactNode } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';

type ConfirmationDialogProps = {
  title: string;
  text: string;
  yesButtonText?: string;
  noButtonText?: string;
  onYes?: () => void;
  onNo?: () => void;
};

type AlertDialogProps = {
  title: string;
  text: string;
  buttonText?: string;
  onClose?: () => void;
};

interface DialogContextProps {
  showAlert: (config: AlertDialogProps) => void;
  showConfirmation: (config: ConfirmationDialogProps) => void;
}

const DialogContext = createContext<DialogContextProps | null>(null);

export const DialogProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [alert, setAlert] = useState<AlertDialogProps | null>(null);
  const [confirmation, setConfirmation] =
    useState<ConfirmationDialogProps | null>(null);

  const showAlert = (config: AlertDialogProps) => {
    setAlert(config);
  };

  const showConfirmation = (config: ConfirmationDialogProps) => {
    setConfirmation(config);
  };

  const handleCloseAlert = () => {
    if (alert) {
      alert.onClose?.();
      setAlert(null);
    }
  };

  const handleYes = () => {
    if (confirmation) {
      confirmation.onYes?.();
      setConfirmation(null);
    }
  };

  const handleNo = () => {
    if (confirmation) {
      confirmation.onNo?.();
      setConfirmation(null);
    }
  };

  return (
    <DialogContext.Provider value={{ showAlert, showConfirmation }}>
      {children}
      {alert && (
        <Dialog open onClose={handleCloseAlert}>
          <DialogTitle>{alert.title}</DialogTitle>
          <DialogContent>{alert.text}</DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAlert}>
              {alert.buttonText || 'OK'}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {confirmation && (
        <Dialog open onClose={handleNo}>
          <DialogTitle>{confirmation.title}</DialogTitle>
          <DialogContent>{confirmation.text}</DialogContent>
          <DialogActions>
            <Button onClick={handleNo}>
              {confirmation.noButtonText || 'Não'}
            </Button>
            <Button onClick={handleYes}>
              {confirmation.yesButtonText || 'Sim'}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </DialogContext.Provider>
  );
};

export const useDialog = (): DialogContextProps => {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error('useDialog must be used within a DialogProvider');
  }
  return context;
};
