import { FieldChangeEventHandler } from 'src/engine/types';
import { useRenderMedicalReport } from 'src/hooks/queries/useRenderMedicalReport';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

type Props = {
  moduleName: string | undefined;
  examName: string | undefined;
  formValues: Record<string, any>;
};

export const TextMode: React.FC<Props> = ({
  moduleName,
  examName,
  formValues,
}) => {
  const { data: reportText } = useRenderMedicalReport(
    moduleName,
    examName,
    formValues,
  );

  return (
    <section className="flex h-[calc(100vh-64px)] w-full items-start justify-center overflow-auto bg-gray-800">
      <div className="my-10 h-[297mm] w-[210mm] bg-white p-20 font-['arial'] text-black shadow-xl shadow-gray-950 drop-shadow-xl">
        <Markdown
          disallowedElements={['pre', 'code']}
          unwrapDisallowed
          className="unreset"
          rehypePlugins={[rehypeRaw]}
        >
          {reportText ?? ''}
        </Markdown>
      </div>
    </section>
  );
};
