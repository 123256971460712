import { useEffect, useRef } from 'react';
import { checkFieldCondition, RenderChildren } from 'src/engine/RenderChildren';
import { ExamUIStructure, FieldChangeEventHandler } from 'src/engine/types';

type Props = {
  activeSectionName: string;
  uiStructure: ExamUIStructure;
  formValues: Record<string, any>;
  onFieldChange: FieldChangeEventHandler;
  onActiveSectionChange: (activeSectionName: string) => void;
};

export const Content: React.FC<Props> = ({
  activeSectionName,
  uiStructure,
  formValues,
  onFieldChange,
  onActiveSectionChange,
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!wrapperRef.current) return;

    const activeSectionElement = wrapperRef.current.querySelector(
      `#${activeSectionName}`,
    );
    if (activeSectionElement) {
      wrapperRef.current.scrollTo({
        top: (activeSectionElement as HTMLElement).offsetTop - 120,
        behavior: 'smooth',
      });
    }
  }, [activeSectionName]);

  return (
    <div
      ref={wrapperRef}
      className="h-[calc(100vh-64px)] grow overflow-auto px-40 py-14"
    >
      <form ref={formRef}>
        {uiStructure.sections.map((section: any) => {
          const isVisible = section.visible_if
            ? checkFieldCondition(section.visible_if, formValues)
            : true;

          if (!isVisible) {
            return null;
          }

          return (
            <div key={section.name}>
              <h2
                className="mb-4 text-4xl font-semibold text-gray-100"
                id={section.name}
              >
                {section.title}
              </h2>
              {section.description && (
                <p className="mb-4 text-xl font-light tracking-wide text-gray-500">
                  {section.description}
                </p>
              )}
              <div className="mb-28 flex select-none flex-col gap-3 text-gray-200">
                <RenderChildren
                  parentName={section.name}
                  items={section.children}
                  onFieldChange={onFieldChange}
                  formValues={formValues}
                />
              </div>
            </div>
          );
        })}
      </form>
    </div>
  );
};
