import { ExamUIStructure } from 'src/engine/types';
import { useQuery } from '@tanstack/react-query';

export const useExamUI = (module?: string, exam?: string) => {
  return useQuery<ExamUIStructure>({
    queryKey: ['exam-ui-structure', module, exam],
    queryFn: () => fetchExamUI(module!, exam!),
    enabled: !!module && !!exam,
  });
};

export const fetchExamUI = async (module: string, exam: string) => {
  const res = await fetch(
    `${process.env.REACT_APP_PUBLIC_API_BASE_URL}/module/${module}/exam/${exam}`,
  );
  return res.json();
};
