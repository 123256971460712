import React from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';
import {
  Condition,
  ExamField,
  ExamFieldWithOptions,
  ExamLabel,
  FieldChangeEventHandler,
  UIItem,
  UIItemWithChildren,
} from './types';

type RenderFieldProps = {
  name: string;
  field: any; //ExamField;
  formValues: Record<string, any>;
  onFieldChange: FieldChangeEventHandler;
};

const RenderField: React.FC<RenderFieldProps> = ({
  name,
  field,
  formValues,
  onFieldChange,
}) => {
  const isDisabled = isUIItemDisabled(field, formValues);
  const isVisible = isUIItemVisible(field, formValues);

  if (!isVisible) {
    return null;
  }

  if (field.field_type === 'checkbox') {
    return (
      <FormControlLabel
        control={
          <Checkbox
            name={name}
            onChange={(e) => onFieldChange(e, field)}
            disabled={isDisabled}
            checked={formValues[name] || false}
          />
        }
        label={field.label}
        style={{ opacity: isDisabled ? 0.5 : 1 }}
      />
    );
  }

  if (field.field_type === 'number') {
    return (
      <FormControl>
        <TextField
          name={name}
          type="number"
          onChange={(e) => onFieldChange(e as any, field)}
          value={formValues[name] || ''}
          disabled={isDisabled}
          style={{ opacity: isDisabled ? 0.5 : 1 }}
          label={field.label}
        />
      </FormControl>
    );
  }

  if (isSelectField(field)) {
    return (
      <FormControl
        disabled={isDisabled}
        style={{ opacity: isDisabled ? 0.5 : 1 }}
      >
        <InputLabel id={name}>{field.label}</InputLabel>
        <Select
          name={name}
          labelId={name}
          onChange={(e) => onFieldChange(e as any, field)}
          label={field.label}
          sx={{ minWidth: 200 }}
          disabled={isDisabled}
          value={formValues[name] || ''}
        >
          {field.options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  if (isRadioGroup(field)) {
    return (
      <FormControl
        component="fieldset"
        disabled={isDisabled}
        style={{ opacity: isDisabled ? 0.5 : 1 }}
      >
        <FormLabel component="legend">{field.label}</FormLabel>
        <RadioGroup name={name} onChange={(e) => onFieldChange(e, field)}>
          {field.options.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio />}
              label={option.label}
              checked={formValues[name] === option.value}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  }

  if (isLabel(field)) {
    return <div style={{ opacity: isDisabled ? 0.3 : 1 }}>{field.text}</div>;
  }

  return null;
};

export const isUIItemDisabled = (
  item: ExamField | ExamLabel,
  formValues: Record<string, any>,
) => {
  return item.disabled_if
    ? checkFieldCondition(item.disabled_if, formValues)
    : false;
};

export const isUIItemVisible = (
  item: ExamField | ExamLabel,
  formValues: Record<string, any>,
) => {
  return item.visible_if
    ? checkFieldCondition(item.visible_if, formValues)
    : true;
};

export const RenderChildren: React.FC<{
  parentName: string;
  items: UIItem[];
  formValues: Record<string, any>;
  onFieldChange: FieldChangeEventHandler;
}> = ({ parentName, items, formValues, onFieldChange }) => {
  return (
    <>
      {items.map((item, i) => {
        const itemName = item.name ? `${parentName}.${item.name}` : parentName;

        if (isField(item)) {
          return (
            <RenderField
              key={itemName}
              name={itemName}
              field={item}
              formValues={formValues}
              onFieldChange={onFieldChange}
            />
          );
        }

        if (isRow(item)) {
          return (
            <div key={i} className="flex flex-nowrap items-center gap-10">
              <RenderChildren
                parentName={itemName}
                items={item.children}
                onFieldChange={onFieldChange}
                formValues={formValues}
              />
            </div>
          );
        }

        return null;
      })}
    </>
  );
};

export function checkFieldCondition(
  condition: Condition,
  formValues: Record<string, any>,
) {
  const fieldValue = formValues[condition.field];

  if (condition.isFalsy !== undefined) {
    return !fieldValue === condition.isFalsy;
  }

  if (condition.isTruthy !== undefined) {
    return !!fieldValue === condition.isTruthy;
  }

  if (typeof condition.value !== undefined) {
    return fieldValue === condition.value;
  }

  console.error('Invalid condition', condition);
}

function isField(item: UIItem): item is ExamField {
  return item.item_type === 'field' || isLabel(item);
}

function isLabel(item: UIItem): item is ExamLabel {
  return item.item_type === 'label';
}

function isSelectField(item: UIItem): item is ExamFieldWithOptions {
  return isField(item) && item.field_type === 'select' && 'options' in item;
}

function isRadioGroup(item: UIItem): item is ExamFieldWithOptions {
  return (
    isField(item) && item.field_type === 'radio_group' && 'options' in item
  );
}

function isRow(item: UIItem): item is UIItemWithChildren {
  return item.item_type === 'row';
}
