import { useQuery } from '@tanstack/react-query';

export const useRenderMedicalReport = (
  module?: string,
  exam?: string,
  examData?: any,
) => {
  return useQuery<string>({
    queryKey: ['render-medical-report', module, exam, examData],
    queryFn: () => fetchMedicalReportText(module!, exam!, examData!),
    enabled: !!module && !!exam && !!examData,
  });
};

export const fetchMedicalReportText = async (
  module: string,
  exam: string,
  examData: any,
) => {
  const res = await fetch(
    `${process.env.REACT_APP_PUBLIC_API_BASE_URL}/module/${module}/exam/${exam}/report`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(examData),
    },
  );
  const data = await res.json();
  return data.reportContent;
};
