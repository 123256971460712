import React, { createContext, useState, ReactNode } from 'react';

type Mode = 'FORM' | 'TEXT';

export type GlobalStateContextProps = {
  mode: Mode;
  currentExamType: string;
  currentModule: string;
  setMode: (mode: Mode) => void;
  setCurrentExamType: (examType: string) => void;
  setCurrentModule: (module: string) => void;
};

export const GlobalStateContext = createContext<
  GlobalStateContextProps | undefined
>(undefined);

export const GlobalStateProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [mode, setMode] = useState<Mode>('FORM');
  const [currentExamType, setCurrentExamType] = useState<string>('');
  const [currentModule, setCurrentModule] = useState<string>('');

  return (
    <GlobalStateContext.Provider
      value={{
        mode,
        currentExamType,
        currentModule,
        setMode,
        setCurrentExamType,
        setCurrentModule,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};
