import { useContext } from 'react';
import {
  GlobalStateContext,
  GlobalStateContextProps,
} from 'src/contexts/globalStateContext';

export const useGlobalState = (): GlobalStateContextProps => {
  const context = useContext(GlobalStateContext);
  if (context === undefined) {
    throw new Error('useGlobalState must be used within a GlobalStateProvider');
  }
  return context;
};
