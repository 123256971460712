import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import cn from 'classnames';
import { LuMousePointerClick as IconFormMode } from 'react-icons/lu';
import { PiTextTBold as IconTextMode } from 'react-icons/pi';
import { TbReportMedical as ProvisoryLogo } from 'react-icons/tb';
import { useGlobalState } from 'src/hooks/useGlobalState';

type Props = {
  className?: string;
};

export const Header: React.FC<Props> = ({ className }) => {
  const { mode, setMode } = useGlobalState();

  return (
    <header
      className={cn(
        className,
        'flex h-16 w-full items-center justify-between border-b border-gray-800/50 px-4',
      )}
    >
      <h1 className="text-md flex items-center gap-2 font-semibold text-blue-400">
        <ProvisoryLogo />
        Laudo Assistido
      </h1>
      <Stack direction="row" spacing={1} className="items-center">
        <IconFormMode />
        <Switch
          color="primary"
          checked={mode === 'TEXT'}
          onChange={({ target }) => setMode(target.checked ? 'TEXT' : 'FORM')}
        />
        <IconTextMode />
      </Stack>
    </header>
  );
};
