import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import { ExamPage } from './pages/ExamPage';
import { InternalLayout } from './layouts/Internal';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { GlobalStateProvider } from './contexts/globalStateContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { DialogProvider } from './contexts/dialogContext';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<InternalLayout />}>
      <Route path="/" element={<></>} />
      <Route path="/module">
        <Route path=":module_name" element={<ExamPage />}>
          <Route path="exam">
            <Route path=":exam_name" element={<ExamPage />} />
            <Route index element={<Navigate to="/" />} />
          </Route>
        </Route>
      </Route>
    </Route>,
  ),
);

export const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <GlobalStateProvider>
        <ThemeProvider theme={darkTheme}>
          <DialogProvider>
            <CssBaseline /> <RouterProvider router={router} />
          </DialogProvider>
        </ThemeProvider>
      </GlobalStateProvider>
    </QueryClientProvider>
  );
};
